<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">Google Analytics par site</p>

      <v-autocomplete
        outlined
        dense
        v-model="departmentShortnameSelected"
        :items="departmentList"
        :menu-props="{ maxHeight: '400', zIndex: 13 }"
        label="Département"
        item-text="name"
        item-value="shortname"
        class="select-department ml-16 shrink"
      ></v-autocomplete>
      <!-- @change="setDepartment" -->

      <v-autocomplete
        outlined
        dense
        v-model="frequencySelected"
        :items="frequencyList"
        :menu-props="{ maxHeight: '400', zIndex: 13 }"
        label="Périodicité"
        item-text="name"
        item-value="id"
        class="select-frequency ml-4 shrink"
        @change="setFrequency"
      ></v-autocomplete>

      <v-autocomplete
        outlined
        dense
        v-model="dateSelected"
        :items="dateList"
        :menu-props="{ maxHeight: '400', zIndex: 13 }"
        label="Date"
        item-text="date"
        item-value="date"
        class="select-date ml-4 shrink"
        @change="getData"
      ></v-autocomplete>
    </div>

    <v-text-field
      v-model="searchStr"
      label="Search"
      @input="search"
      dense
    ></v-text-field>

    <div v-if="filteredData.length !== 0">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">Nom du site (id)</th>
              <th class="text-center">Domaine</th>
              <th class="text-center">Sessions</th>
              <th class="text-center">Views</th>
              <th class="text-center">Views / Session</th>
              <th class="text-center">GA View Id used</th>
              <th class="text-center">Sample ratio</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filteredData" :key="item.id">
              <td class="text-left">
                <b class="primary--text">
                  {{ item.department_id ? "-> " : "" }}
                  {{ item.name }} ({{ item.site_id }})
                </b>
              </td>

              <td class="text-left">{{ item.domain }}</td>
              <td class="text-right">
                {{ item.department_id ? "-> " : "" }}
                <b>{{ item.sessions }}</b>
              </td>
              <td class="text-right">{{ item.views }}</td>
              <td class="text-right">
                {{ item.views_by_session }}
              </td>
              <td class="text-right">{{ item.ga_view_id }}</td>
              <td class="text-right">
                {{ item.sample_ratio ? `${item.sample_ratio}%` : "100%" }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div v-else>
      {{ noDataMessage }}
    </div>
  </div>
</template>
<script>
import { mdiPencil } from "@mdi/js";
import router from "@/router";
import axios from "@axios";
import { saveRmraView } from "@/utils/rmr-analytics";

let defaultNoDataMessage = "Retrieving data ...";

export default {
  name: "GaSites",
  props: {
    departmentShortnameSelected: String,
  },
  watch: {
    departmentShortnameSelected: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        console.log("val", val, "oldVal", oldVal);
        router.push({ name: `ga-sites-${val}` });
        this.getData();
      },
    },
  },
  created() {
    let previousDays = this.getPreviousDays(10);
    this.previousDayStr = previousDays[0];

    previousDays = previousDays.map((x) => {
      return { date: x };
    });
    this.dailyDateList = previousDays;
    this.dateSelected = this.previousDayStr;

    let previousMondayDate = this.getPreviousMonday();
    this.previousMondayStr = this.dateToStr(previousMondayDate);
    let previousMondays = this.getPreviousMondays(previousMondayDate, 10);
    previousMondays = previousMondays.map((x) => {
      return { date: x };
    });
    this.weeklyDateList = previousMondays;

    let previousFirstOfMonthDate = this.getPreviousFirstOfMonth();
    this.previousFirstOfMonthStr = this.dateToStr(previousFirstOfMonthDate);
    let previousFirstOfMonthList = this.getPreviousFirstOfMonthList(
      previousFirstOfMonthDate,
      10
    );
    previousFirstOfMonthList = previousFirstOfMonthList.map((x) => {
      return { date: x };
    });
    this.monthlyDateList = previousFirstOfMonthList;

    this.dateList = this.dailyDateList;
  },
  mounted() {
    saveRmraView(this.$route);
    this.getData();
  },
  data() {
    return {
      componentKey: 0,
      usePrettyNumbers: true,
      departmentList: [
        { id: 0, name: "Aucun", shortname: "main" },
        // { id: 1, name: "Content to commerce", shortname: "ctc" },
        // { id: 2, name: "Audience", shortname: "adn" },
        { id: 3, name: "CRM", shortname: "crm" },
      ],
      departmentIdSelected: null,
      frequencyList: [
        { id: 2, name: "daily" },
        { id: 3, name: "weekly" },
        { id: 5, name: "monthly" },
      ],
      frequencySelected: 2,
      dateList: [],
      dateSelected: null,
      dailyDateList: [],
      previousDayStr: null,
      weeklyDateList: [],
      previousMondayStr: null,
      monthlyDateList: [],
      previousFirstOfMonthStr: null,
      searchStr: "",
      searchTimeout: null,
      noDataMessage: defaultNoDataMessage,
      data: [
        /* {
        "site_id": 1,
        "name": "Vie pratique féminin",
        "code": "viepratique",
        "shortname": "vpf",
        "domain": "www.viepratique.fr",
        "department_id": null,
        "frequency": 2,
        "date": "2022-09-14",
        "ga_view_id": 165996786,
        "views": 362017,
        "sessions": 90597,
        "views_by_session": 3.9959,
        "sample_ratio": null
        },... */
      ],
      icons: {
        mdiPencil,
      },
      filteredData: [],
    };
  },
  methods: {
    prettyNbs(x, sep = " ") {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
    },
    setDepartment() {
      router.push({ name: `ga-sites-${this.departmentShortnameSelected}` });
      this.getData();
    },
    setFrequency() {
      if (this.frequencySelected == 5) {
        this.dateList = this.monthlyDateList;
        this.dateSelected = this.previousFirstOfMonthStr;
      } else if (this.frequencySelected == 3) {
        this.dateList = this.weeklyDateList;
        this.dateSelected = this.previousMondayStr;
      } else {
        this.dateList = this.dailyDateList;
        this.dateSelected = this.previousDayStr;
      }
      this.getData();
    },
    async getData() {
      this.data = [];
      this.filteredData = [];
      this.noDataMessage = defaultNoDataMessage;
      let propsDepartmentShortname = this.departmentShortnameSelected;
      this.departmentIdSelected = this.departmentList.filter(function (el) {
        return el.shortname == propsDepartmentShortname;
      })[0]["id"];
      if (!this.frequencySelected || !this.dateSelected) {
        console.log("missing params");
        return false;
      }
      // let url = `/ga_sites/filtered/3/5/2022-08-08`; // 3 is the CRM department id, 5 is monthly
      const url = `/ga_sites/filtered/${this.departmentIdSelected}/${this.frequencySelected}/${this.dateSelected}`;
      console.log("url", url);
      const { data } = await axios.get(url);
      console.log("response", data);
      if ("error" in data) {
        this.noDataMessage = data.error;
        return;
      }
      // JSON.parse(JSON.stringify(data));
      this.data = data;
      this.setFilteredData();
    },
    search() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      setTimeout(() => {
        this.setFilteredData();
      }, 500);
    },
    setFilteredData() {
      const filteredData = [];
      const searchStrLC = this.searchStr.toLowerCase();
      for (let i = 0; i < this.data.length; i++) {
        if (
          this.searchStr == "" ||
          this.data[i]["name"].toLowerCase().includes(searchStrLC) ||
          this.data[i]["domain"].toLowerCase().includes(searchStrLC)
        ) {
          if (this.usePrettyNumbers) {
            this.data[i]["views"] = this.prettyNbs(this.data[i]["views"]);
            this.data[i]["sessions"] = this.prettyNbs(this.data[i]["sessions"]);
          }
          filteredData.push(this.data[i]);
        }
      }
      this.filteredData = filteredData;
    },
    dateToStr(date) {
      return date.toISOString().split("T")[0];
    },
    getPreviousDays(n) {
      const list = [];
      const d = new Date();
      const previousDay = new Date(d.getTime());
      previousDay.setDate(d.getDate() - 1);
      for (let i = 0; i < n; i++) {
        list.push(this.dateToStr(previousDay));
        previousDay.setDate(d.getDate() - (1 + i));
      }
      return list;
    },
    getPreviousMonday() {
      const date = new Date();
      const day = date.getDay() || 7;
      if (day !== 1) date.setHours(-24 * (day - 1));
      date.setHours(date.getHours() + 25);
      date.setHours(-24 * 7);
      return date;
    },
    getPreviousMondays(previousMondayDate, n) {
      const list = [];
      const d = new Date(previousMondayDate.getTime());
      for (let i = 0; i < n; i++) {
        list.push(this.dateToStr(d));
        d.setHours(-24 * 7);
      }
      return list;
    },
    getPreviousFirstOfMonth() {
      let date = new Date();
      date = new Date(date.getFullYear(), date.getMonth() - 1, 2);
      date.setHours(-5);
      return date;
    },
    getPreviousFirstOfMonthList(previousFirstOfMonthDate, n) {
      const list = [];
      const d = new Date(previousFirstOfMonthDate.getTime());
      for (let i = 0; i < n; i++) {
        list.push(this.dateToStr(d));
        d.setMonth(d.getMonth() - 1);
      }
      return list;
    },
  },
  computed: {},
};
</script>

<style scoped></style>
